<template>
  <div class="dev-item" id="dev-node">
    <div class="w">
      <div class="wl-title2">
        <div class="gang"></div>
        <div class="t">
          <!-- PolySmartChain 网络 -->
          {{ $t("dev.box2T") }}
        </div>
        <div class="gang"></div>
      </div>
      <div class="networkList">
        <div
          class="item"
          v-for="(item, index) in $t('dev.box2Network')"
          :key="index"
        >
          <div class="li">
            <div class="imgBox">
              <img src="../../assets/images/logo.png" alt="" />
            </div>
            <div class="t">
              <!-- PSC主网 -->
              {{ item.title }}
            </div>
          </div>
          <div class="li">
            <div class="l">
              <div class="key">
                <!-- 名称 -->
                {{ item.nameKey }}
                ：
              </div>
              <div class="val">
                <!-- PolySmartChain -->
                {{ item.nameVal }}
              </div>
            </div>
            <div class="r">
              <div class="key">
                <!-- 链ID -->
                {{ item.chainIdKey }}：
              </div>
              <div class="val">
                <!-- 6999 -->
                {{ item.chainIdVal }}
              </div>
            </div>
          </div>
          <div class="li">
            <div class="l">
              <div class="key">
                <!-- RPC -->
                {{ item.rpcKey }}：
              </div>
              <div class="val">
                <!-- https://seed0.PolySmartChain.com -->
                {{ item.rpcVal }}
              </div>
            </div>
            <div class="r">
              <div class="span">
                <div class="key">
                  <!-- 货币 -->
                  {{ item.tokenKey }}：
                </div>
                <div class="val lan">
                  <!-- PSC -->
                  {{ item.tokenVal }}
                </div>
              </div>
              <a href="javascript:;">
                <div class="but" @click="addNetWork()">
                  <!-- 连接钱包 -->
                  {{ item.but }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="logo">
        <img src="../../assets/images/v4/dev/box2Logo.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {
    // 向metamask添加网络
    async addNetWork() {
      await window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              // 链id
              chainId: "0x1b57",
              nativeCurrency: {
                name: "PSC",
                symbol: "PSC", // 2-6 characters long
                decimals: 18,
              },
              // 链名称
              chainName: "PSC",
              // 链节点
              rpcUrls: ["https://seed0.PolySmartChain.com"],
              // 浏览器网站
              blockExplorerUrls: ["https://scan.polysmartchain.com"],
            },
          ],
        })
        .then(() => {
          // console.log("添加成功");
          this.$message.success("success");
          return true;
        })
        .catch(() => {
          // console.log("添加失败");
          this.$message.success("error");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.dev-item {
  // height: 100vh;
  // background: linear-gradient(0deg, #dae6f2, #edf4fc);
  background-image: url("../../assets/images/v4/dev/box2Bg.png");
  background-size: 100% 100%;
  .w {
    // background-color: pink;
    // height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 142px;
    // padding-bottom: 142px;
    box-sizing: border-box;
    text-align: center;
    .networkList {
      margin-top: 16px;
      .item {
        height: 335px;
        background: #ffffff;
        box-shadow: 0px 5px 20px 0px rgba(194, 211, 235, 0.5);
        border-radius: 10px;
        margin-top: 66px;
        padding: 37px 44px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .li {
          display: flex;
          text-align: left;
          align-items: center;
          .l {
            flex: 1;
          }
          .r {
            flex: 1;
            display: flex;
            align-items: center;
            .span {
              flex: 1;
            }
            .but {
              padding: 0 36px;
              display: flex;
              height: 54px;
              line-height: 54px;
              background: linear-gradient(-90deg, #10c1fc, #3f65f9);
              box-shadow: 0px 5px 20px 0px rgba(79, 136, 213, 0.5);
              border-radius: 27px;
              font-size: 20px;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .imgBox {
            width: 64px;
            height: 64px;
            background: #ffffff;
            box-shadow: 0px 5px 20px 0px rgba(194, 211, 235, 0.5);
            border-radius: 50%;
            text-align: center;
            line-height: 64px;
            img {
              width: 44px;
              margin-top: 10px;
            }
          }
          .t {
            height: 28px;
            font-size: 30px;
            font-weight: 500;
            color: #333333;
            margin-left: 28px;
          }
          .key {
            height: 19px;
            font-size: 20px;
            font-weight: 400;
            color: #999999;
            display: initial;
          }
          .val {
            
            font-family: SourceHanSansCN-Regular;
            height: 25px;
            display: initial;
            font-size: 24px;
            font-weight: 400;
            color: #333333;
          }
          .lan {
            height: 18px;
            font-size: 24px;
            font-weight: bold;
            color: #0168ec;
          }
        }
      }
    }
    .logo {
      margin-top: 136px;
    }
  }
}
</style>
