<template>
  <div class="dev-item">
    <div class="w">
      <div class="t">
        <!-- 开发者主页 -->
        {{ $t("dev.box1T") }}
      </div>
      <div class="p pfont">
        <!-- Polysmartchain.com
        帮助您通过PSC编写关于基本概念和开发堆栈的相关文档，还有一些教程让您开始和运行。 -->
        {{ $t("dev.box1P") }}
      </div>
      <a href="javascript:;">
        <div class="but" @click="uti.goPath(store.link.dev.box1But)">
          <!-- 开始  -->
          {{ $t("dev.box1But") }}
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.dev-item {
  height: 100vh;
  background: linear-gradient(0deg, #dae6f2, #edf4fc);
  .w {
    // background-color: pink;
    height: 100vh;
    background-image: url("../../assets/images/v4/dev/box1Bg.png");
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 200px;
    box-sizing: border-box;
    text-align: center;
    .t {
      // height: 95px;
      font-size: 100px;
      font-weight: bold;
      color: #333333;
    }
    .p {
      width: 820px;
      height: 66px;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      margin: 0 auto;
      margin-top: 74px;
    }
    .but {
      display: inline-block;
      margin-top: 106px;
      padding: 0 70px;
      height: 54px;
      line-height: 54px;
      text-align:center;
      background: linear-gradient(-90deg, #10c1fc, #3f65f9);
      box-shadow: 0px 5px 20px 0px rgba(79, 136, 213, 0.5);
      border-radius: 27px;
      width: 80px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
