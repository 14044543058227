<template>
  <div class="dev-item">
    <div class="w">
      <div class="wl-title2" v-if="false">
        <div class="gang"></div>
        <div class="t">
          <!-- PSC水龙头 -->
          {{ $t("dev.box3T1") }}
        </div>
        <div class="gang"></div>
      </div>
      <div class="getTokenBox" v-if="false">
        <input type="text" />
        <div class="but">
          <!-- 给我PSC币 -->
          {{ $t("dev.box3But") }}
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xiala"></use>
          </svg>
        </div>
      </div>
      <div class="wl-title2">
        <div class="gang"></div>
        <div class="t">
          <!-- 浏览相关文档 -->
          {{ $t("dev.box3T2") }}
        </div>
        <div class="gang"></div>
      </div>
      <div class="wordList">
        <div
          class="item"
          v-for="(item, index) in $t('dev.box3ItemList')"
          :key="index"
        >
          <div class="t">
            <img
              :src="
                require(`../../assets/images/v4/dev/devbox3${index + 1}.png`)
              "
              alt=""
            />
            <div class="span">{{ item.title }}</div>
          </div>
          <div class="ul">
            <div class="li" v-for="(it, ind) in item.ul" :key="ind">
              <a href="javascript:;" @click="uti.goPath(it.url)">
                <div class="key">
                  {{ it.key }}
                </div>
              </a>
              <div class="val">{{ it.val }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.dev-item {
  // height: 100vh;
  // background: linear-gradient(0deg, #dae6f2, #edf4fc);
  background-image: url("../../assets/images/v4/dev/box3BG2.png"),
    url("../../assets/images/v4/home/box7LB.png");
  background-position: right bottom, left 280px;
  background-repeat: no-repeat;
  .w {
    // background-color: pink;
    // height: 100vh;
    padding-top: 50px;
    padding-bottom: 300px;
    box-sizing: border-box;
    text-align: center;
    .getTokenBox {
      margin: 0 auto;
      width: 758px;
      height: 74px;
      background: rgba(235, 253, 255, 0);
      border: 1px solid #015fea;
      // border-image: linear-gradient(90deg, #015fea, #00bffa) 1 1;

      border-radius: 10px;
      display: flex;
      margin-top: 77px;
      margin-bottom: 239px;
      input {
        flex: 1;
        border: none;
        outline: none;
        background: none;
        font-weight: 400;
        padding: 0 19px;
      }
      .but {
        padding: 0 40px;
        display: inline-block;
        height: 74px;
        background: linear-gradient(90deg, #015eea, #00c0fa);
        border-radius: 10px;
        line-height: 74px;
        font-size: 29px;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .wordList {
      padding-top: 36px;
      .item {
        // height: 240px;
        background: #ffffff;
        box-shadow: 0px 2px 20px 0px rgba(194, 211, 235, 0.5);
        border-radius: 10px;
        padding: 38px 49px;
        box-sizing: border-box;
        margin-top: 32px;
        .t {
          display: flex;
          align-items: center;
          // img {
          //   width: 42px;
          //   height: 41px;
          // }
          .span {
            font-size: 50px;
            font-weight: 500;
            color: #333333;
            display: inline-block;
          }
        }
        .ul {
          display: flex;
          flex-wrap: wrap;
          .li {
            width: 25%;
            text-align: left;
            .key {
              font-size: 24px;
              font-weight: 500;
              color: #0168ec;
              margin-top: 49px;
              width: 243px;
            }
            .val {
              font-family: SourceHanSansCN-Regular;
              font-size: 18px;
              font-weight: 400;
              color: #999999;
              margin-top: 15px;
              margin-bottom: 5px;
              width: 243px;
            }
          }
        }
      }
    }
  }
}
</style>
